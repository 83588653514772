// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: var(--ion-color-mediumgrey);
  /** success **/
  --ion-color-success: #6dcd00;
  /** warning **/
  --ion-color-warning: #ffc409;
  /** danger **/
  --ion-color-danger: #ff0000;

  --ion-color-background: #f8f8f8;
  /** light **/
  --ion-color-light: #eef0ee;
  /** mediumlight **/
  --ion-color-mediumlight: #dcdedc;
  /** grey **/
  --ion-color-grey: #b4b6b4;
  /** grey **/
  --ion-color-mediumgrey: #646664;
  /** dark **/
  --ion-color-dark: #3c3e3c;
  /** mediumdark **/
  --ion-color-mediumdark: #000000;

  --ion-font-family:  'Poppins';
  --ion-default-font: 'Poppins';
  font-family: 'Poppins', sans-serif;

  --ion-grid-padding:               16px;
  --ion-grid-padding-xs:            16px;
  --ion-grid-padding-sm:            16px;
  --ion-grid-padding-md:            100px;
  --ion-grid-padding-lg:            100px;
  --ion-grid-padding-xl:            100px;
}
