//Global Button Styles
button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--ion-color-mediumlight);
  border-radius: 10px;
  color: var(--ion-color-dark);
  font-weight: 100;
  font-size: 16px;
}

.button {
  &-start-header {
    min-height: 40px;
    text-transform: uppercase;
    background: var(--ion-color-dark);
    width: 80%;
    border: none;
    font-size: 14px;
    padding: 5px;
    color: var(--ion-color-light);
  }

  &-start-body {
    text-transform: uppercase;
    font-size: 14px;
    height: 40px;
    margin: 25px 0 25px 0;
  }

  &-confirm {
    height: 40px;
    text-transform: uppercase;
    background: var(--ion-color-light);
    margin-top: 20px;
    width: 100%;
    font-weight: 300;
  }

  &-timeslot {
    width: 100%;
    height: 35px;
    background: #fff;

    &.selected {
      border: 1px solid var(--ion-color-dark)
    }
  }
}

.button-icon {
  color: var(--ion-color-dark);
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.tablinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  button {
    font-size: 16px;
    border-radius: 25px;
    background: #fff;
    min-height: 40px;
    width: 100%;
  }
  button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button:last-of-type {
    padding-left: 15px;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .active {
    background: var(--ion-color-light)
  }
}
