:root {
  body, html {
    min-height: -webkit-fill-available;
  }

  h1 {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 48px;
    font-style: italic;
  }

  h2 {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 36px;
    font-style: italic;
  }

  h3 {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 24px;
    font-style: italic;
    margin: 0
  }

  h4 {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--ion-color-mediumgrey);
    margin-bottom: 5px;
  }

  p {
    margin: 2px;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--ion-color-grey);
  }

  .client-title {
    font-family: 'Dancing Script', cursive;
    color: var(--ion-color-dark);
    font-weight: 700;
    &-header {
      font-size: 28px;
    }
    &-footer {
      font-size: 26px;
    }
  }


  .subtext-light {
    font-family: Poppins, sans-serif;
    font-weight: 100;
    font-size: 24px;
  }

  .text-light {
    font-family: Poppins, sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--ion-color-mediumgrey);
  }

  .text-bold {
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: var(--ion-color-mediumgrey);
  }

  .infotext-light {
    font-family: Poppins, sans-serif;
    font-weight: 100;
    font-size: 12px;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .headline-group {
    margin-bottom: 35px;
  }

  ion-grid {
    min-height: calc(100% - 100px);
  }

  ion-header{
    border-bottom: 2px solid var(--ion-color-mediumlight);
    &::after {
      background-image: none;
    }
  }

  ion-calendar {
    background: none;
    p {
      font-size: 16px !important;
    }
  }

}
