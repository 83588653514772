:root {
  @media only screen and (max-width: 1023px) {

    p, .text-bold, .text-light, button, span, h4 {
      font-size: 18px;
    }

    .appointment .additional-information--customer div:not(.comment) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .tablinks {
      button {
        font-size: 13px;
      }
    }
  }
}
